<template>
  <hero-page hero="../../assets/images/AboutHero.png" title="Cookies">
    <a id="id.gjdgxs"></a>
    <p class="c13"><span class="c1 c11">Cookie Policy</span></p>
    <p class="c4">
      <span class="c2 c1">Information about our use of cookies</span>
    </p>
    <p class="c3">
      <span class="c0"
        >Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with
        a good experience when you browse our website and also allows us to improve our site. By continuing to browse
        the site, you are agreeing to our use of cookies.</span
      >
    </p>
    <p class="c3">
      <span class="c0"
        >A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your
        computer if you agree. Cookies contain information that is transferred to your computer&#39;s hard drive.</span
      >
    </p>
    <p class="c3"><span class="c0">We use the following cookies: </span></p>
    <ul class="c5 lst-kix_list_19-0 start">
      <li class="c3 c6">
        <span class="c2 c1">Strictly necessary cookies.</span
        ><span class="c0"
          >&nbsp;These are cookies that are required for the operation of our website. They include, for example,
          cookies that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing
          services.
        </span>
      </li>
      <li class="c3 c6">
        <span class="c2 c1">Analytical/performance cookies.</span
        ><span class="c0"
          >&nbsp;They allow us to recognise and count the number of visitors and to see how visitors move around our
          website when they are using it. This helps us to improve the way our website works, for example, by ensuring
          that users are finding what they are looking for easily.
        </span>
      </li>
      <li class="c3 c6">
        <span class="c1 c2">Functionality cookies.</span
        ><span class="c0"
          >&nbsp;These are used to recognise you when you return to our website. This enables us to personalise our
          content for you, greet you by name and remember your preferences (for example, your choice of language or
          region).</span
        >
      </li>
      <li class="c3 c6">
        <span class="c2 c1">Targeting cookies.</span
        ><span class="c0"
          >&nbsp;These cookies record your visit to our website, the pages you have visited and the links you have
          followed. We will use this information to make our website and the advertising displayed on it more relevant
          to your interests. We may also share this information with third parties for this purpose.</span
        >
      </li>
    </ul>
    <p class="c3">
      <span class="c0"
        >Please note that third parties (including, for example, advertising networks and providers of external services
        like web traffic analysis services) may also use cookies, over which we have no control. These cookies are
        likely to be analytical/performance cookies or targeting cookies.
      </span>
    </p>
    <p class="c10">
      <span class="c1">We </span
      ><span class="c0"
        >will not collect any personally identifiable information (i.e. information that is about you and identifies
        you) from you unless otherwise stated.</span
      >
    </p>
    <p class="c9"><span class="c0"></span></p>
    <p class="c3">
      <span class="c0"
        >You block cookies by activating the setting on your browser that allows you to refuse the setting of all or
        some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you
        may not be able to access all or parts of our site.
      </span>
    </p>
    <p class="c9"><span class="c0"></span></p>
    <p class="c3 c14"><span class="c0"></span></p>
    <div>
      <p class="c7"><span class="c0"></span></p>
      <p class="c7"><span class="c0"></span></p>
    </div>
  </hero-page>
</template>

<script>
import HeroPage from "../../components/Common/HeroPage";
import Landing from "../../layouts/Landing";

export default {
  name: "Cookies",
  data() {
    return {
      body:
        "Qui labore cillum commodo nostrud enim sint est cupidatat non. Et aute duis deserunt laborum ea ex tempor consequat commodo dolor in eiusmod. Laboris qui sit consequat minim sit officia qui magna mollit id aliqua mollit. Non labore laboris in elit pariatur. Est ullamco ad laboris magna quis qui elit esse tempor aute ipsum ut. Commodo irure deserunt dolor consequat anim nulla laboris reprehenderit incididunt deserunt. Et veniam adipisicing ea anim adipisicing incididunt minim."
    };
  },
  components: {
    HeroPage
  },
  created() {
    this.$emit(`update:layout`, Landing);
  }
};
</script>

<style scoped>
li {
  color: #000000;
  font-size: 16px;
}
p {
}
</style>
