<template>
  <div>
    <div class="staticHero">
      <v-img src="../../assets/images/AboutHero.png"> </v-img>
    </div>
    <div class="block">
      <v-container>
        <h2>
          {{ title }}
        </h2>
        <p>
          {{ body }}
        </p>
        <slot />
      </v-container>
    </div>
  </div>
</template>

<script>
import Landing from "@/layouts/Landing";
export default {
  name: "HeroPage",
  props: {
    hero: String,
    title: String,
    body: String
  },
  created() {
    this.$emit(`update:layout`, Landing);
  }
};
</script>
